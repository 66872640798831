import React from 'react';
import { useState } from "react";
import Swal from "sweetalert2";
import background from "../../img/images.jpg";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import '../../button.css';
import {displayErrorToast,displayError,parseBoolean} from '../../helpers';

import ErrorToast from '../../ErrorToast';
import { checkForDuplicateTax } from './TaxUtils';
import FloatingControls from '../../components/FloatingControls';
import StatusDropDown from '../../components/StatusDropDown';
import LoadingOverlay from '../../components/LoadingOverlay';
import { createTax,getAllTax } from '../../services/TaxServices';


function showGSTOnly(){

      document.getElementById("taxIntegrated").disabled = true;
        document.getElementById("taxIntegrated").required = false;
    
        document.getElementById("taxTCS").disabled = true;
        document.getElementById("taxTCS").required = false;
    
        document.getElementById("taxTDS").disabled = true;
        document.getElementById("taxTDS").required = false;
}

function showIGSTOnly(){
  document.getElementById("taxSGST").disabled = true;
  document.getElementById("taxSGST").required = false;

  document.getElementById("taxCGST").disabled = true;
  document.getElementById("taxCGST").required = false;

  document.getElementById("taxTCS").disabled = true;
  document.getElementById("taxTCS").required = false;

  document.getElementById("taxTDS").disabled = true;
  document.getElementById("taxTDS").required = false;
}

function showTCSOnly(){
  document.getElementById("taxSGST").disabled = true;
  document.getElementById("taxSGST").required = false;
  document.getElementById("taxCGST").disabled = true;
  document.getElementById("taxCGST").required = false;
  document.getElementById("taxIntegrated").disabled = true;
  document.getElementById("taxIntegrated").required = false;
  document.getElementById("taxTDS").disabled = true;
  document.getElementById("taxTDS").required = false;
}

function showTDSOnly(){
  document.getElementById("taxSGST").disabled = true;
  document.getElementById("taxSGST").required = false;
  document.getElementById("taxCGST").disabled = true;
  document.getElementById("taxCGST").required = false;
  document.getElementById("taxIntegrated").disabled = true;
  document.getElementById("taxIntegrated").required = false;
  document.getElementById("taxTCS").disabled = true;
  document.getElementById("taxTCS").required = false;
}

function showAllGST(){

  document.getElementById("taxIntegrated").disabled = false;
	document.getElementById("taxTCS").disabled = false;
	document.getElementById("taxTDS").disabled = false;
	document.getElementById("taxSGST").disabled = false;
	document.getElementById("taxCGST").disabled = false;
}

function Tax() {
  const [cookies] = useCookies(['myToken']);
  const [inputs, setInputs] = useState({tax_value1:0,tax_value2:0});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(true);
  const navigate = useNavigate();

  const handleTax  = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    {                             //Depends upon need of requirement u can select GST,IGST,TCS,TDS and also tax name must not be empty
    
 
    if(name === 'tax_type'){
           showAllGST();
      if (value === 'gst') {
        showGSTOnly();
        
    } else if (value === 'igst') {
      showIGSTOnly();
       
    } else if (value === 'tcs') {
      showTCSOnly();
    } else if (value === 'tds') {
      showTDSOnly();
    }
   
  }
  else if((name === 'taxCGST') || (name === 'taxTCS') || (name === 'taxTDS') || (name === 'taxIntegrated')){
       
    setInputs(values => ({...values, ['tax_value1']: value}))
  }  else if(name === 'taxSGST'){
       
    setInputs(values => ({...values, ['tax_value2']: value}))
  }  

}
  setInputs(values => ({...values, [name]: value}))
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name+"name")
    console.log(value+"value")

    
    if(name === 'taxName')
    {
  
      if(!value.trim())
      {
   
        setError(values => ({...values, [name]: "TaxName must not be empty..."}))
        setIsSubmitting(false); 
      }
      else{
        setError(values => ({...values, [name]: ""}))
        setIsSubmitting(true); 
      }
    
    }

   
    setInputs(values => ({...values, [name]: value}))

}
  const [inProgress, setInProgress] = useState(false);
const handleSubmit=(event)=> {
    event.preventDefault();
 
    if ((Object.values(error).every(x => !x)) && isSubmitting) {  //If all the required fields are field u can select the save button
            Swal.fire({title: 'Do you want to save?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
      
              if (result.isConfirmed) { 
                createTax(cookies, {
                  name:inputs.taxName,
                  tax_value1:inputs.tax_value1,
                  tax_value2:inputs.tax_value2,
                  tax_type:inputs.tax_type,
                  valid_from:inputs.valid_from,
                  valid_upto:inputs.valid_upto,
                  user_remarks:inputs.user_remarks,
                  status:parseBoolean(inputs.status)
                 
                })
                .then((response) =>
                {
                  console.log(JSON.stringify(response)+"tax_form");
                  Swal.fire('Saved!', '', 'success') 
                  view();
                  Cancel();
                }).catch((error) =>
                {
                  console.log(error.response.data);
                  displayError(error.response.data,"Saved Failed");
                })
                
              } 
              else if (result.isDismissed) 
              {    
                Swal.fire('Not saved', '', 'info')  
              }
            });
    }
 
  }  
      const Reload = () => {
       window.location.reload();
      }      
      
      const view = () => {
        navigate('/TaxTable')
      }        
      
      const Back = () => {
        navigate('/Home')
      }

      const Cancel = () => {
        setInputs(() => "")
      }
  return (
      <>
    <div id="outer-container"  className="App" > 
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <ErrorToast/>
          <LoadingOverlay inProgress={inProgress}/>
          <div id="page-wrap">
              <form className="form" onSubmit={handleSubmit}>
                  <div id="header">
                      <h3 className = "text font-weight-bold" style={{color:"RGB(16, 18, 19)",textAlign:"center",fontFamily:"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>TAX SETUP FORM </h3>
                  </div>
      
                  <FloatingControls tableLink="/TaxTable" onCancel={Cancel} enableCancel={true}/>
            <div className="container">
            <Row>
                        <Col xl={6} lg={12} md={12}>
                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue " style={{backgroundColor:"RGB(188,232,253)" ,borderRadius:"10px",fontWeight:"bold",fontFamily:"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>
                <h5 className='col-sm-11'><b>Tax Details</b></h5><br/><br/>
                <label htmlFor="tax_type" className="form-group col-sm-4 text-right">Tax Type</label>
                <select id="tax_type"name="tax_type"  required="" onChange={handleTax} value={inputs.tax_type || ""}className="browser-default custom-select form-control col-sm-7 mandatory-form-control">
                <option value=""></option>
                        <option value="gst" >GST</option>
                        <option value="igst">IGST</option>
                        <option value="tcs">TCS</option>
                        <option value="tds">TDS</option>
                    </select><br/><br/>

                       
                  
                        <label htmlFor="taxName" className="form-group col-sm-4 text-right">Name </label>
                    <input type="text" id="taxName" className="form-control col-sm-7 mandatory-form-control"required name="taxName" onChange={handleChange} value={inputs.taxName || ""} /><br/><br/>
                    {error.name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.name}</strong><br/></>)}
                    
                    <label htmlFor="taxCGST" className="form-group col-sm-4 text-right">Central GST (%) </label>
                    <input type="number" step="any" id="taxCGST"  name="taxCGST" className="form-control col-sm-7 mandatory-form-control" onChange={handleTax} value={inputs.taxCGST || 0}  required /><br/><br/> 

                
                    <label htmlFor="taxSGST" className="form-group col-sm-4 text-right">StateGST (%) </label>
                    <input type="number"  step="any" id="taxSGST" name="taxSGST" className="form-control col-sm-7 mandatory-form-control"onChange={handleTax} value={inputs.taxSGST || 0} required /><br/><br/> 

                       
                    
                    <label htmlFor="taxIntegrated"  className="form-group col-sm-4 text-right">IGST (%) </label>
                    <input type="number"step="any" id="taxIntegrated" name="taxIntegrated" className="form-control col-sm-7 mandatory-form-control"  onChange={handleTax} value={inputs.taxIntegrated || 0} required /><br/><br/>

                    <label htmlFor="taxTCS"  className="form-group col-sm-4 text-right">TCS (%) </label>
                    <input type="number" step="any" id="taxTCS" name="taxTCS" className="form-control col-sm-7 mandatory-form-control" onChange={handleTax} value={inputs.taxTCS || 0} required/><br/><br/>

                    <label htmlFor="taxTDS"  className="form-group col-sm-4 text-right">TDS (%) </label>
                    <input type="number" step="any" id="taxTDS" name="taxTDS" className="form-control col-sm-7 mandatory-form-control" onChange={handleTax} value={inputs.taxTDS || 0} required/><br/><br/>
                    
                </div>
                        </Col>
                <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue " style={{backgroundColor:"RGB(188,232,253)" ,borderRadius:"10px",fontWeight:"bold",fontFamily:"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>
                <h5 className='col-sm-11'><b>Status Details</b></h5><br/><br/>
                
                    <label htmlFor="valid_from" className="form-group col-sm-4 text-right">Valid From (Date) </label>
                    <input type="date" name="valid_from"id="valid_from" className="form-control col-sm-7 mandatory-form-control" onChange={handleChange} value={inputs.valid_from || ""} required /><br/><br/>
                    
                    <label htmlFor="valid_upto" className="form-group col-sm-4 text-right">Valid Upto (Date) </label>
                    <input type="date" name="valid_upto"id="valid_upto" className="form-control col-sm-7 mandatory-form-control" onChange={handleChange} value={inputs.valid_upto || ""} required /><br/><br/>
                    
                    <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                    <input type="text" id="user_remarks" className="form-control col-sm-7" name="user_remarks" onChange={handleChange} value={inputs.user_remarks || ""}/><br/><br/>

                    <StatusDropDown status={inputs.status} onChange={handleChange}/>
   
                </div>
                    </Col>
            </Row> 
            </div>
                <div className="footer text-center"><br/>
                    <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter">Save</Button> &nbsp;&nbsp;
                    <Button type="button"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}}className="btn btn-twitter" onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                    <Button   type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={Back}>Home</Button>
                </div>
                </form> 
            </div><br/>
            </div> 
            
        </>
  );
}
export default Tax;
