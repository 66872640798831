import React from 'react';
import { elastic as Menu } from 'react-burger-menu';
import { Menu as AntdMenu } from 'antd';
import './Sidebar.css';
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import {ButtonGroup} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { useState ,useEffect } from "react";
import {Button} from "reactstrap";
import { performLogout } from './services/LoginServices';


export default props => {
  let navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['myToken']);
  const [name, setName,removeName] = useCookies(['myName']);
  const [category, setCategory,removeCategory] = useCookies(['myCategory']);
  const [superuser,setSuperuser,removeSuperuser] = useCookies(['mySuperuser']);
  // const [openKeys, setOpenKeys] = useState([]);
  
  // const handleSubMenuClick = (openKeys) => {
  //   setOpenKeys(openKeys);
  // };
  const handleLogout = e => {
    e.preventDefault();
    performLogout(cookies)
      .then(data => {
        console.log(data,"data_logout");
       
        removeCookie(['myToken']);
        removeName(['myName']);
        if(superuser['mySuperuser'])
        {
          removeSuperuser(['mySuperuser'])
        }
        if(category['myCategory'])
        {
          removeCategory(['myCategory'])
        }
      });
  };
  useEffect(() => {
    if(!cookies['myToken']){
		console.log(cookies['myToken']+"cookies['myToken']")
		window.location.href = '/'
	}
  }, [cookies]);
  return (
    
    <Menu>
      <div style={{marginLeft:"60px"}}>
          <Dropdown drop='down-centered'>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
            <i className="fa fa-user-circle fa-lg"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.ItemText><span style={{color:"cornflowerblue"}}>{name['myName']}</span></Dropdown.ItemText>
              <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
      </div>
      
      <AntdMenu theme="dark" mode="inline" style={{ width: 250,backgroundColor:"rgb(2, 2, 49)",fontWeight:"bold" }} >
        {((superuser['mySuperuser']) || (category['myCategory'].includes("is_rmc_admin") === true)) && (
          <AntdMenu.SubMenu key="admin" title='ADMIN'>
            <AntdMenu.Item key="company"  onClick={() =>{navigate("/Company")}}>
               Company 
            </AntdMenu.Item>
            <AntdMenu.Item  key="plant" onClick={() =>{navigate("/Plant")}}>
              Plant
            </AntdMenu.Item>
            <AntdMenu.Item  key="plant_configuration" onClick={() =>{navigate("/PlantConfiguration")}}>
              Plant Configuration
            </AntdMenu.Item>
            <AntdMenu.Item  key="number_setting" onClick={() =>{navigate("/NumberSetting")}}>
              Number Setting
            </AntdMenu.Item>
            <AntdMenu.Item  key="approval_setting" onClick={() =>{navigate("/ApprovalSetting")}}>
              Approval Setting
            </AntdMenu.Item>
            <AntdMenu.Item  key="unit" onClick={() =>{navigate("/Unit")}}>
              Unit
            </AntdMenu.Item>
            <AntdMenu.Item  key="tax" onClick={() =>{navigate("/Tax")}}>
              Tax
            </AntdMenu.Item>
            <AntdMenu.Item key="product"  onClick={() =>{navigate("/Product")}}>
              Product
            </AntdMenu.Item>
            <AntdMenu.Item  key="sales_representative" onClick={() =>{navigate("/SalesRep")}}>
              Sales Representative
            </AntdMenu.Item>
            <AntdMenu.Item  key="gang" onClick={() =>{navigate("/Gang")}}>
              Gang
            </AntdMenu.Item>
            {((superuser['mySuperuser'])) &&(
              <AntdMenu.Item  key="permission" onClick={() =>{navigate("/Permission")}}>
                Permission
              </AntdMenu.Item>
            )}
            {((superuser['mySuperuser'])) &&(
              <AntdMenu.Item key="ip_address"  onClick={() =>{navigate("/IpAddress")}}>
                IP Address
              </AntdMenu.Item>
            )}
            {((superuser['mySuperuser'])) &&(
              <AntdMenu.Item key="user"  onClick={() =>{navigate("/User")}}>
                User
              </AntdMenu.Item>
            )}
          </AntdMenu.SubMenu>
        )}
        
        {((superuser['mySuperuser']) || (category['myCategory'].includes("is_marketting") === true)) && (
          <AntdMenu.SubMenu key="marketting" title='MARKETTING'>
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_entity_customer_detail") === true) || (category['myCategory'].includes("view_entity_customer_detail") === true)|| (category['myCategory'].includes("change_entity_customer_detail") === true) || (category['myCategory'].includes("delete_entity_customer_detail") === true)) &&(
              <AntdMenu.Item key="customer" onClick={() =>{navigate("/CustomerMain")}}>
                Billing Registration
              </AntdMenu.Item>
            )}
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_entity_consignee_detail") === true) || (category['myCategory'].includes("view_entity_consignee_detail") === true)|| (category['myCategory'].includes("change_entity_consignee_detail") === true) || (category['myCategory'].includes("delete_entity_consignee_detail") === true)) &&(
              <AntdMenu.Item key="consignee" onClick={() =>{navigate("/CustomerSite")}}>
                Site Registration
              </AntdMenu.Item>
            )}
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_quotation") === true) || (category['myCategory'].includes("view_quotation") === true) || (category['myCategory'].includes("edit_quotation") === true) || (category['myCategory'].includes("delete_quotation") === true)) && (
              <AntdMenu.Item key="quotation"  onClick={() =>{navigate("/Quotation")}}>
                Quotation
              </AntdMenu.Item>
            )}
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_sales_order_master") === true) || (category['myCategory'].includes("view_sales_order_master") === true) || (category['myCategory'].includes("change_sales_order_master") === true) || (category['myCategory'].includes("delete_sales_order_master") === true)) && (
              <AntdMenu.Item key="sales_order" onClick={() =>{navigate("/SalesOrder")}}>
                Sales Order
              </AntdMenu.Item>
            )}
          </AntdMenu.SubMenu>
        )}

        {((superuser['mySuperuser']) || (category['myCategory'].includes("is_qty_ctrl") === true)) && (
          <AntdMenu.SubMenu key="quality_control" title='QUALITY CONTROL'>
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_design_mix_master") === true) || (category['myCategory'].includes("view_design_mix_master") === true)|| (category['myCategory'].includes("change_design_mix_master") === true) || (category['myCategory'].includes("delete_design_mix_master") === true)) &&(
              <AntdMenu.Item key="design_mix" onClick={() =>{navigate("/DesignMix")}}>Design Mix</AntdMenu.Item>
            )}
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_moisture_correction_master") === true) || (category['myCategory'].includes("view_moisture_correction_master") === true)|| (category['myCategory'].includes("change_moisture_correction_master") === true) || (category['myCategory'].includes("delete_moisture_correction_master") === true)) &&(
              <AntdMenu.Item key="moisture_correction"  onClick={() =>{navigate("/MoistureCorrection")}}>
                Moisture Correction
              </AntdMenu.Item>
            )}
            {/* {((superuser['mySuperuser']) || (category['myCategory'][0].Is_CubeTestReport_for_so_add === true) || (category['myCategory'][0].Is_MoistureCorrection_for_so_view === true)|| (category['myCategory'][0].Is_CubeTestReport_for_so_edit === true) || (category['myCategory'][0].Is_CubeTestReport_for_so_delete === true)) &&(
              <AntdMenu.Item key="cube_test_report" onClick={() =>{navigate("/CubeTestReport")}}>
                Cube Test Report
              </AntdMenu.Item>
            )} */}
          </AntdMenu.SubMenu>
        )}

        {((superuser['mySuperuser']) || (category['myCategory'].includes("is_planning")=== true)) && (
          <AntdMenu.SubMenu key="planning" title='PLANNING'>
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_work_schedule_master") === true) || (category['myCategory'].includes("view_work_schedule_master") === true)|| (category['myCategory'].includes("change_work_schedule_master") === true) || (category['myCategory'].includes("delete_work_schedule_master") === true)) &&(
              <AntdMenu.Item key="work_schedule" onClick={() =>{navigate("/WorkSchedule")}}>
                Work Schedule
              </AntdMenu.Item>
            )}
          </AntdMenu.SubMenu>
        )}

        {((superuser['mySuperuser']) || (category['myCategory'].includes("is_purchase")=== true)) && (
          <AntdMenu.SubMenu key="purchase" title='PURCHASE'>
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_entity_vendor_detail") === true) || (category['myCategory'].includes("view_entity_vendor_detail") === true)|| (category['myCategory'].includes("change_entity_vendor_detail") === true) || (category['myCategory'].includes("delete_entity_vendor_detail") === true)) &&(
              <AntdMenu.Item key="vendor" onClick={() =>{navigate("/Vendor")}}>
                  Vendor
              </AntdMenu.Item>
            )}
            {/* {((superuser['mySuperuser']) || (category['myCategory'][0].Is_purchaseorder_for_so_add === true) || (category['myCategory'][0].Is_purchaseorder_for_so_view === true)|| (category['myCategory'][0].Is_purchaseorder_for_so_edit === true) || (category['myCategory'][0].Is_purchaseorder_for_so_delete === true)) &&( */}
                <AntdMenu.Item key="purchase_order" onClick={() =>{navigate("/PurchaseOrder")}}>
                Purchase Order
                </AntdMenu.Item>
            {/* )}
            {((superuser['mySuperuser']) || (category['myCategory'][0].Is_purchaseinvoice_for_so_add === true) || (category['myCategory'][0].Is_purchaseinvoice_for_so_view === true)|| (category['myCategory'][0].Is_purchaseinvoice_for_so_edit === true) || (category['myCategory'][0].Is_purchaseinvoice_for_so_delete === true)) &&(
                <AntdMenu.Item key="purchase_invoice" onClick={() =>{navigate("/PurchaseInvoice")}}>
                Purchase Invoice
                </AntdMenu.Item>
            )} */}
          </AntdMenu.SubMenu>
        )}

        {((superuser['mySuperuser']) || (category['myCategory'].includes("is_dispatch")  === true)) && (
          <AntdMenu.SubMenu key="dispatch" title='DISPATCH'>
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_pump_clearance") === true) || (category['myCategory'].includes("view_pump_clearance") === true)|| (category['myCategory'].includes("edit_pump_clearance") === true) || (category['myCategory'].includes("delete_pump_clearance") === true)) &&(
              <AntdMenu.Item key="pump_clearance" onClick={() =>{navigate("/PumpClearance")}}>
                  Pump Clearance
              </AntdMenu.Item>
            )}
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_del_challan_master") === true) || (category['myCategory'].includes("view_del_challan_master") === true)|| (category['myCategory'].includes("change_del_challan_master") === true) || (category['myCategory'].includes("delete_del_challan_master") === true)) &&(
              <AntdMenu.Item key="delivery_challan" onClick={() =>{navigate("/DeliveryChallan")}}>
                DeliveryChallan
              </AntdMenu.Item>
            )}
          </AntdMenu.SubMenu>
        )}

        {((superuser['mySuperuser']) || (category['myCategory'].includes("is_gate")  === true)) && (
          <AntdMenu.SubMenu key="gate" title='GATE'>
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_gate_pass_master") === true) || (category['myCategory'].includes("view_gate_pass_master") === true)|| (category['myCategory'].includes("change_gate_pass_master") === true) || (category['myCategory'].includes("delete_gate_pass_master") === true)) &&(
              <>
             {/* <AntdMenu.Item key="check_out" onClick={() =>{navigate("/CheckOut")}}>
                  Check Out
              </AntdMenu.Item>*/}
              <AntdMenu.Item key="check_in"  onClick={() =>{navigate("/CheckIn")}}>
                  Check In
              </AntdMenu.Item>
              <AntdMenu.Item key="gate_pass" onClick={() =>{navigate("/VehicleGatePass")}}>
                  Vehicle Gate Pass
              </AntdMenu.Item>
              </>
            )}
          </AntdMenu.SubMenu> 
        )}     

      {((superuser['mySuperuser']) || (category['myCategory'].includes("is_accounts")  === true)) && (
        <AntdMenu.SubMenu key="accounts" title='ACCOUNTS'>
          {((superuser['mySuperuser']) || (category['myCategory'].includes("add_grouping") === true) || (category['myCategory'].includes("view_grouping") === true)|| (category['myCategory'].includes("edit_grouping") === true) || (category['myCategory'].includes("delete_grouping") === true)) &&(
            <AntdMenu.Item key="group_form" onClick={() =>{navigate("/GroupForm")}}>
              Group Form
            </AntdMenu.Item>
          )}
          {((superuser['mySuperuser']) || (category['myCategory'].includes("add_entity_bank_detail") === true) || (category['myCategory'].includes("view_entity_bank_detail") === true)|| (category['myCategory'].includes("change_entity_bank_detail") === true) || (category['myCategory'].includes("delete_entity_bank_detail") === true)) &&(
              <AntdMenu.Item key="bank" onClick={() =>{navigate("/Bank")}}>
              Bank
            </AntdMenu.Item>
          )}
          {((superuser['mySuperuser']) || (category['myCategory'].includes("add_sales_invoice") === true) || (category['myCategory'].includes("view_sales_invoice") === true)|| (category['myCategory'].includes("edit_sales_invoice") === true) || (category['myCategory'].includes("delete_sales_invoice") === true)) &&(
              <AntdMenu.Item key="sales_invoice" onClick={() =>{navigate("/SalesInvoice")}}>
              Sales Invoice
            </AntdMenu.Item>
          )}
          {/*  {((superuser['mySuperuser']) || (category['myCategory'][0].Is_receiptvoucher_add === true) || (category['myCategory'][0].Is_receiptvoucher_view === true)|| (category['myCategory'][0].Is_receiptvoucher_edit === true) || (category['myCategory'][0].Is_receiptvoucher_delete === true)) &&(
              <AntdMenu.Item key="receipt_voucher" onClick={() =>{navigate("/ReceiptVoucher")}}>
                Receipt Voucher
              </AntdMenu.Item>
          )} */}
          {/* {((superuser['mySuperuser']) || (category['myCategory'][0].Is_creditnote_add === true) || (category['myCategory'][0].Is_creditnote_view === true)|| (category['myCategory'][0].Is_creditnote_edit === true) || (category['myCategory'][0].Is_creditnote_delete === true)) &&(
            <AntdMenu.Item key="credit_note" onClick={() =>{navigate("/CreditNote")}}>
              Credit Note
            </AntdMenu.Item>
          )} */}
        </AntdMenu.SubMenu>
      )}     

      {((superuser['mySuperuser']) || (category['myCategory'].includes("is_transport")  === true)) && (
        <AntdMenu.SubMenu key="transport" title='TRANSPORT'>
          {((superuser['mySuperuser']) || (category['myCategory'].includes("add_equipment_master") === true) || (category['myCategory'].includes("view_equipment_master") === true)|| (category['myCategory'].includes("change_equipment_master") === true) || (category['myCategory'].includes("delete_equipment_master") === true)) &&(
            <AntdMenu.Item key="equipment" onClick={() =>{navigate("/Equipment")}}>
              Equipment
            </AntdMenu.Item>
          )}
        </AntdMenu.SubMenu>
      )}

      {((superuser['mySuperuser']) || (category['myCategory'].includes("is_store") === true)) && (
          <AntdMenu.SubMenu key="store" title='STORE'>
            {((superuser['mySuperuser']) || (category['myCategory'].includes("add_gmr_master") === true) || (category['myCategory'].includes("view_gmr_master") === true)|| (category['myCategory'].includes("edit_gmr_master") === true) || (category['myCategory'].includes("delete_gmr_master") === true)) &&(
              <AntdMenu.Item key="grn" onClick={() =>{navigate("/GoodsReceiptNote")}}>
                Goods Receipt Note
              </AntdMenu.Item>
            )}
          </AntdMenu.SubMenu>
          )} 

      {((superuser['mySuperuser']) ||  (category['myCategory'].includes("view_accounts_reports") === true) || (category['myCategory'].includes("view_transport_reports") === true) || (category['myCategory'].includes("view_marketting_reports") === true)|| (category['myCategory'].includes("view_dispatch_reports") === true) || (category['myCategory'].includes("view_store_reports") === true)) &&(
        <AntdMenu.Item key="report" onClick={() =>{navigate("/ReportAll")}}>
          REPORTS
        </AntdMenu.Item> 
      )}   


    
    {/* {((superuser['mySuperuser']) || (category['myCategory'][0].is_consumptionnote  === true)) && (
    <Dropdown>
        <Dropdown.Toggle className="dropdown-custom-1">
          CONSUMPTION NOTE
          <span style={{color:"#1a1e21"}}></span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {((superuser['mySuperuser']) || (category['myCategory'][0].Is_consumptionnote_add === true) || (category['myCategory'][0].Is_consumptionnote_view === true)|| (category['myCategory'][0].Is_consumptionnote_edit === true) || (category['myCategory'][0].Is_consumptionnote_delete === true)) &&(
              <Dropdown.Item onClick={() =>{navigate("/ConsumptionNote")}}>
                     Consumption Note
              </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>  
    )}  */}
        
      </AntdMenu>
    </Menu>
  );
  };   
       
        
