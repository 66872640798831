import React from 'react';
import { useState ,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiLock, BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import {useLocation ,Link,useParams} from 'react-router-dom';
import {getAllPlants } from '../../services/PlantServices';
import {displayErrorToast,displayError} from '../../helpers';
import{getAllDeliveryChallanForCheckOut, getAllDeliveryChallans} from '../../services/DeliveryChallanServices';
import { createCheckOut,getCheckOutNumber } from '../../services/CheckOutServices';
import ErrorToast from '../../ErrorToast';
import { getPlantDetails} from '../../services/PlantServices';
import { updateCheckOut,getCheckOutDetails } from '../../services/CheckOutServices';
import SummaryIcon from '../../components/SummaryIcon';
import {getCustomerMainDetails } from '../../services/CustomerMainServices';
import { getAllCustomerSites,getCustomerSiteDetails } from '../../services/CustomerSiteServices';
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import Select from 'react-select';
import { getDisplayDate } from '../../utils/DateUtils';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';


function CheckOutEdit() {
    const location = useLocation();
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [deliverychallan,setDeliveryChallan]=React.useState([]);
    const [alldeliverychallan,setAllDeliveryChallan]=React.useState([]);
    const[allDc,setAllSelectedDc] = useState([]);
    const[selectedSite,setSelectedSite]= React.useState({});
    const[selectedWorkschedule,setSelectedWorkshedule] = useState({}); 
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const [inputs, setInputs] = useState({
        checkout_no:'',
        checkout_date:'',
        checkout_time:'',
        gross_weight:0,
        net_weight:0,
        tare_weight:0,
        weighment_slip_time:'', 
        weighment_slip_no:'',
        weighment_slip_date:'',
        driver_name:'',
        opening_km:0,
        opening_hm:0,
        slump:0,
        user_remarks:'',
        dc_qty:0
    });
const [Ws, setWs]= useState({});
const [error, setError] = useState({});
const[selectedCustomerSite,setSelectedCustomerSite] = useState({});
const[selectedCustomer,setSelectedCustomer] = useState({});
const [userplants, setUserPlant] = React.useState([]);
const [inProgress, setInProgress] = useState(false);
const [Dc,setDc]=React.useState([]);
const [visibleDivId, setVisibleDivId] = useState(null); 
const [isLoading, setIsLoading] = useState(true);  
const [plant, setPlant] = React.useState([]);
const[selectedDC,setSelectedDC] = useState({});

const navigate = useNavigate();
const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    var plant_id = document.getElementById('plant_id').value
    // if((name === 'movement_type') ){
    //    if(plant_id & (value === 'dc')){
    //     getAllDeliveryChallanForCheckOut(cookies,plant_id,value)
    //     .then(deliverychallanList => {
    //         console.log(JSON.stringify(deliverychallanList)+"HC_deliverychallanList")
        
    //             const deliverychallans = deliverychallanList.delivery_challan_list.map(
    //                 deliverychallan => {
    //                     return { value: deliverychallan.id,label: deliverychallan.delivery_challan_no}
    //                 }
    //             );
    //             console.log(JSON.stringify(deliverychallans)+"deliverychallans")
              
    //             setDeliveryChallan(deliverychallans);
                
    //             setAllDeliveryChallan(deliverychallanList.delivery_challan_list)
                   
    //     })
    //     .catch((error) =>
    //     {
    //         setIsLoading(false);
    //       console.log(error.response.data);
    //       displayError(error.response.data," Failed");
    //     })
    //     //
      
   
    //         getCheckOutNumber(cookies,plant_id,value)
    //         .then(check_out => {
    //             setIsLoading(false);
    //             setInputs(values => ({...values, ['checkout_no']: check_out.checkout_no}));
    //         })
    //         .catch((error) =>
    //         {
    //             setIsLoading(false);
    //           console.log(error.response.data);
    //           displayError(error.response.data," Failed");
    //         })
         
    
    //     //
    //    }
    //    else{
    //     setInputs(values => ({...values, ['dc_no']:'',['checkout_no']:''}));
    //     setDeliveryChallan([])
    //     setSelectedDC({})
    // } 
    // }
   
    
    setInputs(values => ({...values, [name]:value}));
}

React.useEffect(() => {
    getAllPlants(cookies)

    .then (
        plantList => {
            
            const plant = plantList.plant_list.filter(obj => obj.status).map(
                plant => {
                    return { value: plant.id, label: plant.plant_alias }
                }
            );
            setPlant(plant);
        }
    )
}, []);

useEffect( () => {
    getAllDeliveryChallans(cookies)
    .then( deliverychallanList => {
        //console.log(JSON.stringify(workscheduleList)+"HC_wrkshedulelist")
    
            const deliverychallans = deliverychallanList.delivery_challan_list.map(
                deliverychallan => {
                    return { value: deliverychallan.id,label:  deliverychallan.prefix+deliverychallan.delivery_challan_no }
                }
            );
          
            setDeliveryChallan(deliverychallans);
            setAllDeliveryChallan(deliverychallanList.delivery_challan_list)
    
    })
  
  }, []);

React.useEffect(() => {
        
        
    setInputs(values => ({...values,
       
        ['checkout_date']: getCurrentDate(),
        ['checkout_time']: getCurrentTime(),
        ['weighment_slip_date']: getCurrentDate(),
        ['weighment_slip_time']: getCurrentTime(),
       
    }))
      }, []);

const handleChangeDCDetails = (event) => {
       
    console.log(event.target.value);
    const dcId = event.target.value;
    const name = event.target.name;

 
  console.log(dcId+"salesorderId")
    if (dcId) {
        
        setInputs(values =>({...values,[name]:dcId}))
        const selected_dc = alldeliverychallan.filter(obj => obj.id == dcId)[0];
        setSelectedDC(selected_dc);
        setInputs(values =>({...values,[name]:dcId,['opening_km']:selected_dc.vehicle.opening_km,['opening_hm']:selected_dc.vehicle.opening_hm}));
        console.log(JSON.stringify(selected_dc)+"selected_dc")
        console.log(JSON.stringify(deliverychallan)+"deliverychallan")

        if(selected_dc.transport_mode === 'customer'){
          document.getElementById('customer_mode').style.display = "block";
        } 
        else{
            document.getElementById('customer_mode').style.display = "none";
        }

    } 
    else {
   
        setSelectedDC({});
      
    }

    setInputs(values => ({...values, [name]:dcId}));

  } 


  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

   const handleMenuClose = () => {
    if (inputValue.trim() !== '') {
      const newOption = { value: inputValue, label: inputValue };
      setOptions([...options, newOption]);
      setSelectedOption(newOption);
      setInputValue('');
    }
  };

  const handleChangePlant = (event) => {
    const name = event.target.name;
    const value = event.target.value;
   
    try{
        setInputs(values => ({...values, [name]: value}));
        if(name === 'plant_id' && value){

            // if(selected_dc.transport_mode === 'customer'){
                // document.getElementById('customer_mode').style.display = "block";
            //   } 
            //   else{
            //       document.getElementById('customer_mode').style.display = "none"
            //   }
            setIsLoading(true);
            // var movement_type = document.getElementById('movement_type').value
            //  if(movement_type === 'dc'){
  
            //console.log(inputs.movement_type+"movement_type")
            // console.log(document.getElementById('movement_type').value)
            getAllDeliveryChallanForCheckOut(cookies,value)
            .then(deliverychallanList => {
                console.log(JSON.stringify(deliverychallanList)+"HC_deliverychallanList")
            
                    const deliverychallans = deliverychallanList.delivery_challan_list.map(
                        deliverychallan => {
                            return { value: deliverychallan.id,label:  deliverychallan.prefix+deliverychallan.delivery_challan_no }
                        }
                    );
                    console.log(JSON.stringify(deliverychallans)+"deliverychallans")
                  
                    setDeliveryChallan(deliverychallans);
                    
                    setAllDeliveryChallan(deliverychallanList.delivery_challan_list)
                       
            })
            .catch((error) =>
            {
                setIsLoading(false);
              console.log(error.response.data);
              displayError(error.response.data," Failed");
            })
            getCheckOutNumber(cookies,value)
            .then(check_out => {
                setIsLoading(false);
                setInputs(values => ({...values, ['checkout_no']: check_out.checkout_no}));
            })
            .catch((error) =>
            {
                setIsLoading(false);
              console.log(error.response.data);
              displayError(error.response.data," Failed");
            })
        //   }
     } else if (name === 'plant_id' && !value) {

            document.getElementById('customer_mode').style.display = "none";
            setInputs(values => ({...values, ['delivery_challan_no']: '',['checkout_no']:''}));
        }
      }
     catch (e) {
    
      displayErrorToast(e);
    }
  }

  const showDCDetails = () => {

    if (inputs.id) {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'DELIVERY CHALLAN DETAILS',
                    [
                     {label: 'SO NO', value: inputs.order_no},
                    {label: 'DELIVERY CHALLAN DATE', value: inputs.delivery_challan_date},
                     {label: 'GRADE', value: inputs.grade},
                     {label: 'DC QTY', value: inputs.dc_qty},
                     {label: 'VEHICLE', value: inputs.vehicle_no},
                     {label: 'TRANSPORT MODE', value: inputs.transport_mode}
                  
                    ])
            }, '', 'info');   
    }
    
}
// const showSiteDetails = () => {

//     if (selectedDC.id) {
//                 Swal.fire({
//       html:getHTMLForSummaryPopup(
//           'SITE DETAILS',
//           [
//             {label: 'SITE NAME', value: inputs.site_name},
//             {label: 'ADDRESS', value: inputs.address_1},
//             {value: inputs.address_2},
//             {value: inputs.address_3},
//             {label: 'PROJECT/SITE NAME', value: inputs.site_name}
//           ])
//   }, '', 'info');
             
     
          
//     }
    
// }

const showSiteDetails = () => {

    if (selectedDC.id) {
    
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'SITE DETAILS',
                    [
                    {label: 'SITE NAME', value: selectedSite.name},
                    {label: 'ADDRESS', value: selectedSite.address_1},
                    {value: selectedSite.address_2},
                    {value: selectedSite.address_3},
                    {label: 'PROJECT/SITE NAME', value: selectedSite.project_name}
                    ])
            }, '', 'info');   
     
          
    }
    
}

const showCustomerDetails = () => {

   if (selectedDC.id) {
           Swal.fire({
               html:getHTMLForSummaryPopup(
                   'BILLING DETAILS',
                   [
                    {label: 'BILLING NAME', value: selectedCustomer.name},
                    {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name}
                //    {label: 'TRANSPORT MODE', value: selectedCustomer.transport_mode},
                //    {label: 'ADDRESS', value: selectedCustomer.address_1},
                //    {value: selectedCustomer.address_2},
                //    {value: selectedCustomer.address_3},
                // {label: 'PROJECT/SITE NAME', value: selectedCustomer.project_name}

                   ])
           }, '', 'info');
    //    })          
   }  
}

const validate_items = (name,value,regular_expr,error_message) =>
{
   try{
     
        if(value.trim())     //If  required condition is valid it accept ,otherwise it is Invalid.
        {
            if(!regular_expr.test(value.trim())) //If regex is not statisfied ,then it is false
            {
                setError(values => ({...values, [name]: error_message}))
                setIsSubmitting(false); 
            }
            else            //If regex is statisfied ,then it is true
            {
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
            }
        }
        else                 //If value must not be empty this condition is false
        {
            setError(values => ({...values, [name]: 'must not be empty...'}))
            setIsSubmitting(false);
        }                    // If condition is valid then display the values
        setInputs(values => ({...values, [name]: value}))
    }
 
   catch (e)
      {
       displayErrorToast(e);
      }
    }

const handleChangeWeightDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
   

    if (name === 'tare_weight' || name === 'gross_weight') {
         var tare_weight = document.getElementById('tare_weight').value;
         var gross_weight = document.getElementById('gross_weight').value;     
         if((tare_weight === '') || (tare_weight === NaN) ){
            tare_weight = 0;
            
         }
         if((gross_weight === '') || (gross_weight === NaN) ){
            gross_weight = 0;
            
         }
         console.log(tare_weight+"tare_weight")
         console.log(gross_weight+"gross_weight")
        var net_weight = parseFloat(gross_weight) - parseFloat(tare_weight);
        console.log(net_weight+"net_weight")
        setInputs(values => ({...values, ['net_weight']: net_weight})) 
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  };
const handleSubmit=(event)=> 
{
    event.preventDefault();
    console.log(JSON.stringify(inputs)+"update_Submit")

    Swal.fire({title: 'Do you want to update?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,  
    cancelButtonText: `No`,
    }).then((result) => {  
    if (result.isConfirmed)  //If all the required fields are field u can select the save button then choose the option yes or no
    { 
        updateCheckOut(cookies, {
                        id:parseInt(inputs.id),
                        delivery_challan_id:parseInt(inputs.delivery_challan_id),
                        checkout_no:inputs.checkout_no,
                        checkout_date:getDisplayDate(inputs.checkout_date),
                        checkout_time:inputs.checkout_time,
                        gross_weight:parseInt(inputs.gross_weight),
                        net_weight:parseInt(inputs.net_weight),
                        tare_weight:parseInt(inputs.tare_weight),
                        weighment_slip_time:inputs.weighment_slip_time,
                        weighment_slip_no:inputs.weighment_slip_no,
                        weighment_slip_date:getDisplayDate(inputs.weighment_slip_date),
                        driver_name:inputs.driver_name,
                        driver_mobile_no:inputs.driver_mobile_no,
                        opening_km:parseInt(inputs.opening_km),
                        opening_hm:parseInt(inputs.opening_hm),
                        slump:parseInt(inputs.slump),
                        user_remarks:inputs.user_remarks
                    
                     },id)

                     .then(response => {
                        Swal.fire("Update!", "", "success");
                        view();
                      }).catch((error) => {
                          console.log(error.response.data);
                          displayError(error.response.data,"Updated Failed");
                      });
                      
    } 
    else if (result.isDismissed) 
    {    
    Swal.fire('Not saved', '', 'info')  
    }
    });
}
        const view = () => {
          navigate('/CheckOutTable')
        }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            setInputs(() => "")

        }

        const {id}=useParams()
            
        React.useEffect(() => {
          if (id){
        setInProgress(true);
        getCheckOutDetails(cookies, id)
          .then(response => {
              console.log(JSON.stringify(response)+"response_use_effect")
              setInProgress(false);
            setSelectedDC(response)
              setInputs(values => ({...values,['id']:response.id,
              ['plant_id']:response.delivery_challan.work_schedule.plant.id,
              ['delivery_challan_id']: response.delivery_challan.id, 
              ['sales_rep']:response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.salesrep.name,
              ['order_no']:response.delivery_challan.work_schedule.sales_order_detail.sales_order.order_no,
              ['company']:response.delivery_challan.work_schedule.sales_order_detail.sales_order.company.name,
              ['billing_name']:response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.name,
              ['site_name']:response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.project_name,
              ['ship_to']:response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.name,
              ['vehicle_no']:response.delivery_challan.transport_mode === 'own'?response.delivery_challan.vehicle.name : response.delivery_challan.customer_vehicle_no,
              ['grade']:response.delivery_challan.work_schedule.sales_order_detail.product.name,
              ['transport_mode']:response.delivery_challan.transport_mode,
              ['dc_qty']:response.delivery_challan.dc_qty,
              ['checkout_no']:response.checkout_no,
              ['checkout_date']: getDisplayDate(response.checkout_date),
              ['checkout_time']:response.checkout_time,
              ['gross_weight']:response.gross_weight,  
              ['net_weight']:response.net_weight,
              ['tare_weight']: response.tare_weight,
              ['weighment_slip_time']: response.weighment_slip_time, 
              ['weighment_slip_no']: response.weighment_slip_no,
              ['weighment_slip_date']:getDisplayDate(response.weighment_slip_date),
              ['driver_name']:response.driver_name,  
              ['driver_mobile_no']:response.driver_mobile_no,
              ['opening_km']: response.opening_km,
              ['opening_hm']:response.opening_hm,  
              ['slump']:response.slump,
              ['user_remarks']:response.user_remarks,
              ['editing_dtls']:response.editing_dtls
          }));
          if(response.delivery_challan.transport_mode === 'customer') {
             document.getElementById('customer_mode').style.display = 'block'} 
             else{ document.getElementById('customer_mode').style.display = 'none'}
          getCustomerMainDetails(cookies,response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.id)    
          .then(customerObject => {              //Set the details of the customer in the form fields
              setSelectedCustomer(customerObject)
              })
              getCustomerSiteDetails(cookies,response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.id)
              .then(customerObject => {                     //Set the details of the customer in the form fields
                  setSelectedSite(customerObject);
              });
          })
          .catch(error => {
              setInProgress(false);
            //   displayError(error.response.data, "Loading Failed");
            });
      }
        }, []);
    return (
    <>
     <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit} data-id={Ws.Ws_id}> 
          <div id="header">
              <h3 className = "text font-weight-bold page-title" >VEHICLE CHECK OUT EDIT</h3>
          </div>
         
          <FloatingControls tableLink="/CheckOutTable" onCancel={Cancel} enableCancel={false}/>
            <div className="container"> 
         
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel">
                    <h5 className='col-sm-11'><b>DeliveryChallan Details</b></h5><br/><br/>
                    
                    <Row> 
                    <Col xl={6} lg={6} md={6}> 
                    <div className="form-row p-2 my-2 border-secondary p-2 mb-3 form-control-panel">   
                    <label htmlFor="plant_id" className="form-group col-sm-5 text-right"> Plant Location </label>
                        <select id="plant_id"name="plant_id" className="form-control col-sm-6  browser-default custom-select  mandatory-form-control"  required onChange={handleChangePlant} value={inputs.plant_id || ""} disabled>
                        {/* <option value="">Select Plant</option> */}
                                    {plant.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                            ))}
                        </select>
                        <br></br>      
                        <ViewOnlyTextbox 
                name="company" 
                value={selectedDC?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.company?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Company"
                inputClassName="form-control col-sm-6"
                />
                        {/* <br></br>    

                        <label htmlFor="movement_type" className="form-group col-sm-5 text-right">Movement Type</label>
                        <select id="movement_type" name="movement_type" className="form-control col-sm-6 text left  browser-default custom-select mandatory-form-control" required onChange={handleChange} value={""}>
                       
                       <option value="dc">DC</option>
                      <option value="grn">GRN</option>
                      <option value="gatepass">Gate Pass</option>
                      <option value="maintanance">Maintanance</option>
                      <option value="others">Others</option>
                      </select>
                      <br></br>     */}
                      
                      <ViewOnlyTextbox 
                name="billing_name" 
                value={selectedDC?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.customer?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Billing Name"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showCustomerDetails}
                />  
                      
                    
                        <br></br>    

                       
                <ViewOnlyTextbox 
                name="project_name" 
                value={selectedDC?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.project_name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Project/Site Name"
                inputClassName="form-control col-sm-6"
                
                /> 
                        </div>
                     
                        </Col>
                   
                    <Col xl={6} lg={6} md={6}> 
                    <div className="form-row p-2 my-2 border-secondary p-2 mb-3 form-control-panel"> 
                    <label htmlFor="delivery_challan_id" className="form-group col-sm-4 text-right"> DC No </label>
                        <select id="delivery_challan_id" name="delivery_challan_id" className="form-control col-sm-6  mandatory-form-control"  required onChange={handleChangeDCDetails} value={inputs.delivery_challan_id || ""} disabled>
                      
                          {/* <option value="">Select DC No</option> */}
                          {deliverychallan.map((item) => (
                                  <option key={item.value} value={item.value}>
                                  {(item.label)}
                                  </option>
                              ))}
                        </select>
                        <SummaryIcon onClickHandler={showDCDetails}/> 
                    
                   
                       
                        <label htmlFor="checkout_no" className="form-group col-sm-4 text-right">Check Out No.</label>
                        <input type="text" className="form-control col-sm-6 mandatory-form-control" id="checkout_no" value={inputs.checkout_no || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="checkout_no" /><br/>
                  

                        <label htmlFor="wsSlDt" className="form-group col-sm-4 text-right">Check Out Date/Time</label>

<input type="date" className="form-control col-sm-3  mandatory-form-control" name="checkout_date" value={inputs.checkout_date || ""} id="checkout_date" readOnly={true}style={{backgroundColor:"white", cursor: "not-allowed"}} />
<input type="time" id="checkout_time" required name="checkout_time" onChange={handleChange} value={inputs.checkout_time || ""}className="form-control col-sm-3  mandatory-form-control"/>

<br></br>

    
<ViewOnlyTextbox 
                name="ship_to" 
                value={selectedDC?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.name || ""}
                labelClassName="form-group col-sm-4 text-right"
                label="Ship To"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showSiteDetails}
                /> 
                        <br></br>

                        <ViewOnlyTextbox 
                name="vehicle_no" 
                value={selectedDC?.delivery_challan?.transport_mode === 'own'? selectedDC?.delivery_challan?.vehicle?.name : selectedDC?.delivery_challan?.customer_vehicle_no}
                labelClassName="form-group col-sm-4 text-right"
                label="Vehicle No"
                inputClassName="form-control col-sm-6"
                /> 
                        
                        <br></br>
                        <ViewOnlyTextbox 
                name="grade" 
                value={selectedDC?.delivery_challan?.work_schedule?.sales_order_detail.product.name || ""}
                labelClassName="form-group col-sm-4 text-right"
                label="Grade"
                inputClassName="form-control col-sm-6"
                />  
                        <br></br>
                        <ViewOnlyTextbox 
                name="dc_qty" 
                value={selectedDC?.delivery_challan?.dc_qty || ""}
                labelClassName="form-group col-sm-4 text-right"
                label="DC Qty"
                inputClassName="form-control col-sm-6"
                />  
                        </div>
                    </Col>
                    </Row>
        
                    </div>
               
            
            <div id="header">
                <h4 style={{color:"black", textAlign:"center"}}><strong><b>OUT WEIGHTMENT DETAILS</b> </strong></h4>
            </div>
            <Row> 
                <Col xl={6} lg={12} md={12}> 
                   <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel">
                   <h5 className='col-sm-11'><b>Weightment Details</b></h5><br/><br/>

                      
                   <label htmlFor="weighment_slip_no" className="form-group col-sm-4 text-right">Weightment Slip No.</label>
                        <input type="number" id="weighment_slip_no"name="weighment_slip_no" onChange={handleChange} value={inputs.weighment_slip_no || ""}className="form-control col-sm-7"/>
                        
                        
                        <label htmlFor="weighment_slip_date" className="form-group col-sm-4 text-right">Weightment Date/Time</label>

                        <input type="date" className="form-control col-sm-3  mandatory-form-control" name="weighment_slip_date" value={inputs.weighment_slip_date || ""} id="weighment_slip_date" onChange={handleChange}/>
                        <input type="time" id="weighment_slip_time"required name="weighment_slip_time" onChange={handleChange} value={inputs.weighment_slip_time || ""}className="form-control col-sm-4  mandatory-form-control"/>
      

                        <label htmlFor="gross_weight" className="form-group col-sm-4 text-right">Gross Weight</label>
                        <input type="number" id="gross_weight" name="gross_weight" onChange={handleChangeWeightDetails} value={inputs.gross_weight || 0} className="form-control col-sm-7"/>

                        <label htmlFor="tare_weight " className="form-group col-sm-4 text-right">Tare Weight</label>
                        <input type="number"  name="tare_weight" id="tare_weight" onChange={handleChangeWeightDetails} value={inputs.tare_weight || 0} className="form-control col-sm-7"/>
                        
                        <label htmlFor="net_weight " className="form-group col-sm-4 text-right">Net Weight</label>
                        <input type="number" id="net_weight" name="net_weight" onChange={handleChangeWeightDetails}   style={{backgroundColor:"white", cursor: "not-allowed"}}  value={inputs.net_weight || 0} className="form-control col-sm-7"/>

                         <br/><br/>
                
                <br/><br/>      
                    </div>  
                    </Col>
                <Col xl={6} lg={12} md={12}> 
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                <h5 className='col-sm-11'><b>Vehicle Details</b></h5><br/><br/>
                    

                     {/* <label htmlFor="driver_name" className="form-group col-sm-4 text-right">Driver Name </label>
                    
                    <Select options={options} id="driver_name" name="driver_name" value={selectedOption} className="col-sm-7  browser-default custom-select  mandatory-form-control" onChange={handleSelectChange}  required onInputChange={handleInputChange} inputValue={inputValue} onMenuClose={handleMenuClose} isSearchable></Select> */}
                       <label htmlFor="driver_name" className="form-group col-sm-4 text-right">Driver Name</label>
                        <input type="text" id="driver_name" onChange={handleChange} value={inputs.driver_name || ""} required name="driver_name" className="form-control col-sm-7"/>

                        <label htmlFor="driver_mobile_no"className="form-group col-sm-4 text-right  ">Mobile No</label>
                              <input type="text" id="driver_mobile_no" name="driver_mobile_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^\+?[0-9]{1,4}[-\s]?[0-9]{7,10}$/,"invalid Mobile No")} value={inputs.driver_mobile_no|| ""} pattern="[0-9]{10}"  maxLength="10" className="form-control col-sm-7 " /><br/>
                              {error.driver_mobile_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.driver_mobile_no}</strong><br/></>)}
                  
                        <label htmlFor="opening_km" className="form-group col-sm-4 text-right">Opening KM</label>
                        <input  type="number"id="opening_km"  value={inputs.opening_km || 0} name="opening_km"   readOnly={true} className="form-control col-sm-7 mandatory-form-control" style={{backgroundColor:"white", cursor:"not-allowed"}}/>
                        
                        <label htmlFor="opening_hm" className="form-group col-sm-4 text-right">Opening HM</label>
                        <input  type="number"id="opening_hm" name="opening_hm" className="form-control col-sm-7 mandatory-form-control"  value={inputs.opening_hm || 0} readOnly={true} style={{backgroundColor:"white", cursor:"not-allowed"}}/>
                        
                        <label htmlFor="slump" className="form-group col-sm-4 text-right">Slump</label>
                    <input type="number" id="slump"name="slump" className="form-control col-sm-7" value={inputs.slump || 0} onChange={handleChange}/>

                    <label htmlFor="editing_dtls" className="form-group col-sm-4 text-right">Editing Details</label>
                            <textarea rows="4" cols="50" className="form-control col-sm-7" id="editing_dtls" name="editing_dtls" onChange={handleChange} value={inputs.editing_dtls || ""}></textarea>

                    <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks,if any</label>
                <input type="text" className="form-control col-sm-7" id="user_remarks"  name="user_remarks" value={inputs.user_remarks||""} onChange={handleChange} /><br />

                </div>
                <br/><br/>
         
                </Col>
            </Row>  
                    <div id="customer_mode" style={{display:"none"}}>
                    <Row> 
                    <Col xl={12} lg={12} md={8}>   
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                    <h5 className='col-sm-11'><b>Status Details</b></h5><br/><br/>
                    <label htmlFor="cus_status" className="form-group col-sm-2 text-right">Customers Status</label>
                        <select id="cus_status" name="cus_status" className="form-control col-sm-2  browser-default custom-select" required onChange={handleChangeDCDetails} value={inputs.cus_status || ""} disabled>
                
                        <option value="accepted">Accepted</option>
                      <option value="partial accepted">Partial Accepted</option>
                      <option value="cancelled">Cancelled</option>
                      </select>
                      
                      <label htmlFor="accepted_qty" className="form-group col-sm-2 text-right">Accepted Qty</label>
                        <input type="number" id="accepted_qty" value={selectedDC.dc_qty || ""} style={{backgroundColor: "white", cursor: "not-allowed"}} name="accepted_qty" className="form-control col-sm-2" />
                  
                        <label htmlFor="balance_qty" className="form-group col-sm-1 text-right">Balance Qty</label>
                        <input required type="number" id="balance_qty"onChange={handleChangeDCDetails} value={inputs.balance_qty || 0} style={{backgroundColor: "white", cursor: "not-allowed"}} name="balance_qty" className="form-control col-sm-2"/>
                     

                        <label htmlFor="balance_status" className="form-group col-sm-2 text-right">Balance Status</label>
                        <select id="balance_status" name="balance_status" className="form-control col-sm-2  browser-default custom-select" required onChange={handleChangeDCDetails} value={inputs.balance_status || ""} disabled>
                         <option value="nill">Nill</option>
                         </select>
                  
                        <label htmlFor="dc_status" className="form-group col-sm-2 text-right">DC Status</label>
                        <select id="balance_status" name="balance_status" className="form-control col-sm-2  browser-default custom-select" required onChange={handleChangeDCDetails} value={inputs.balance_status || ""} disabled>
                
                <option value="receieved_with_sign">Received With Sign</option>

              </select>

                    </div>

                   
                </Col>
              </Row>
              </div>
                    
                                              
            </div>
            <div className="footer text-center">
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Update</Button> &nbsp;&nbsp;

                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
        </form>
        </div>
    </div>
         </>
    );
  }
  export default CheckOutEdit;




  