import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllPumpClearance, deletePumpClearance } from "../../services/PumpClearanceServices";
function PumpClearanceTable() {
    const InitialData = {
					   
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'plant', 
                headerName: 'Plant'
            },
            {
                fieldName: 'schedule_no', 
                headerName: 'Job'
            },
           
            {
                fieldName: "schedule_date",
                headerName: 'Job Date'
            },
            {
                fieldName: "order_no",
                headerName: 'So No'
            },
            {
                fieldName: "order_date",
                headerName: "Sodt"
            },
            {
                fieldName: "customer_name",
                headerName: "Customer Site"
            },
            {
                fieldName: "grade",
                headerName: "Grade"
            },
            {
                fieldName: "order_quantity",
                headerName: "Order Qty"
            },
            {
                fieldName: "scheduled_qty",
                headerName: "Shedule Qty"
            },
            {
                fieldName: "supplied_qty",
                headerName: "Supplied Qty"
            },
            {
                fieldName: "balance_qty",
                headerName: "Balanced Qty"
            },
            {
                fieldName: "delivery_mode",
                headerName: "Mode Of Delivery"
            },
            {
                fieldName: "pump_id",
                headerName: "Pump Number"
            },
            {
                fieldName: "gang_id",
                headerName: "Gang Name"
            },
 
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
       
       
         
        ],
        [],);

    const onEditPumpClearance = (pumpcleranceId, event) => {
       
       navigate(`/PumpClearanceEdit/${pumpcleranceId}/`);
    };

    const onDeletePumpClearance = (pumpclearanceId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deletePumpClearance(cookies, pumpclearanceId); 
            console.log(`pumpclearance with id ${pumpclearanceId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(pump_csv => {

                const csvRow = {
                    plant:pump_csv.plant,
                    schedule_no: pump_csv.schedule_no,
                    schedule_date: pump_csv.schedule_date,
                    order_no:pump_csv.order_no,
                    order_date:pump_csv.order_date,
                    customer_name:pump_csv.customer_name,
                    order_quantity:pump_csv.order_quantity,
                    grade:pump_csv.grade,
                    shedule_qty:pump_csv.shedule_qty,
                    supplied_qty:pump_csv.supplied_qty,
                    balance_qty:pump_csv.balance_qty,
                    delivery_mode:pump_csv.delivery_mode,
                    pump_id:pump_csv.pump_id,
                    gang_id:pump_csv.gang_id
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of PumpClerance Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(pump_pdf => ({
                plant:pump_pdf.plant,
                schedule_no: pump_pdf.schedule_no,
                schedule_date: pump_pdf.schedule_date,
                order_no:pump_pdf.order_no,
                order_date:pump_pdf.order_date,
                customer_name:pump_pdf.customer_name,
                order_quantity:pump_pdf.order_quantity,
                grade:pump_pdf.grade,
                shedule_qty:pump_pdf.shedule_qty,
                supplied_qty:pump_pdf.supplied_qty,
                balance_qty:pump_pdf.balance_qty,
                delivery_mode:pump_pdf.delivery_mode,
                pump_id:pump_pdf.pump_id,
                gang_id:pump_pdf.gang_id
            })),
            'PumpClearanceList.pdf'
        );
    };

    useEffect(() => {

        getAllPumpClearance(cookies)
        .then( pumpcleranceList => {
            const tableData = pumpcleranceList.work_schedule_list
            .map(pump_clr => ({
                id :pump_clr.id,
                plant:pump_clr.plant.alias_name,
                schedule_no: pump_clr.schedule_no,
                schedule_date: pump_clr.schedule_date,
                order_no:pump_clr.sales_order_detail.sales_order.order_no,
                order_date:pump_clr.sales_order_detail.sales_order.order_date,
                customer_name:pump_clr.sales_order_detail.sales_order.consignee.customer.name,
                order_quantity:pump_clr.sales_order_detail.order_quantity,
                grade:pump_clr.sales_order_detail.product.name,
                scheduled_qty:pump_clr.scheduled_qty,
                supplied_qty:pump_clr.supplied_qty,
                balance_qty:pump_clr.balance_qty,
                delivery_mode:pump_clr.delivery_mode,
                pump_id:pump_clr.pump.name,
                gang_id:pump_clr.gang.name
            }));

            setData({
								  
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of WorkSchedule Form</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of WorkSchedule Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditPumpClearance}
                            onRowDelete={onDeletePumpClearance}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            hideDelete={true}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/PumpClearance")}}>Back</button>
        </div>

        
    );

}



export default PumpClearanceTable;