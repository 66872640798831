import ArchitectureIcon from '@mui/icons-material/Architecture';

function DmixIcon({onClickHandler}) {

    return(
        <>
            <ArchitectureIcon onClick={onClickHandler}
            sx={{cursor: 'pointer',
                color: 'white',
                fontSize: '24px',
                backgroundColor: 'primary.dark',
                margin: '8px 3px 3px 3px',
                padding: '2px',
                borderRadius: '15px'
            }}
            />
        </>
    );
}

export default DmixIcon;